@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ant-btn-sm {
  font-size: 12px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.ant-btn-sm .ant-btn-icon {
  font-size: 16px !important;
}

.text-success {
  color: rgba(166, 206, 57, 1);
  font-size: 40px;
}

.tag-success {
  background-color: rgba(166, 206, 57, 1);
}

.subtitle-primary {
  color: #38B6E9 !important;
}
.ant-progress-text {
  color: #38B6E9 !important;
}

.border-primary {
  border-color: #38B6E9 !important;
}
.subtitle-white {
  color: #fff !important;
}

.bg-primary {
  background-color: #38B6E9 !important;
}

.title-bold {
  font-weight: 700;
}

.ant-btn.ant-btn-rtl span:not(.anticon):dir(rtl) {
  padding-top: 3px;
}

.rtlPt:dir(rtl) {
  padding-top: 3px;
}

.dangerBtn {
  color: #fff !important;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.dangerBtn:hover {
  color: #fff !important;
  background-color: #ff7875 !important;
  border-color: #ff7875 !important;
}

.ant-form-item-required {
  font-weight: 500;
}
.ant-form-item {
  font-weight: 500;
}

.ant-input-number-outlined {
  border-width: 2px !important;
  height: 42px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-picker .ant-picker-suffix {
  color: #38B6E9;
}

.ant-picker-outlined {
  border-width: 2px !important;
  height: 42px !important;
}
.ant-picker-outlined:hover:focus {
  border-color: #179bd0;
}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, .ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: #38B6E9 !important;
}

.ant-input-outlined {
  border-width: 2px !important;
}
.ant-input-outlined:hover:focus {
  border-color: #179bd0;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border-width: 2px !important;
}

#users-main .ant-form-item .ant-form-item-label,
.selectInput {
  overflow: visible !important;
}

.heading {
  border-inline-start: 6px solid #179bd0;
  padding-inline-start: 8px;
}

.ant-picker-dropdown .ant-picker-date-panel,
.ant-picker-dropdown .ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 355px;

  th {
    font-size: 14px;
  }
}

.ant-input {
  border-width: 2 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"] {
  -ms-appearance: textfield;
}

.ant-picker-month-panel .ant-picker-content {
  direction: ltr;
}

.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  width: auto !important;
}

#Employees-main .ant-collapse-item .ant-collapse-header {
  display: flex;
  align-items: flex-end;
}
.highlightedCell {
  background-color: #38B6E9;
  color: #fff;
}

@media screen and (max-width: 768px) {
  #Employees-main .ant-collapse-item .ant-collapse-header {
    flex-direction: column;
  }
}
